import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { BaseQuery } from '../models/query/base-query';
import { BaseApiResponse } from '../models/bases/base-api-response';
import { lastValueFrom, map } from 'rxjs';
import { Scheme } from '../models/scheme';

@Injectable({
  providedIn: 'root',
})
export class SchemeService {
  constructor(private _baseRequestService: BaseRequestService) {}

  getSchemes(query?: BaseQuery) {
    return lastValueFrom(
      this._baseRequestService
        .getJSON<BaseApiResponse<Scheme[]>>('schemes', {
          data: query,
          is_loading: true,
        })
        .pipe(
          map((res) => {
            return res;
          }),
        ),
    );
  }
}
